<template>
    <TwoColumnsLayout leftWidth="240px">
        <template #left>
            <LeftSelectTree title="项目树" v-model:selected="selected" :treeData="treeData" :loading="treeLoading" :fieldNames="{
                title: 'name',
                key: 'selectId',
            }" @select="selectTree"></LeftSelectTree>
        </template>
        <template #right>
            <div class="content">
                <div class="header">
                    <span class="title" v-if="selectedItem">{{ selectedItem.name }}</span>
                    <a-date-picker :value="nowYear" picker="year" :bordered="false" :disabled-date="disabledDate"
                        placeholder="请选择年份" style="width: 100px;" @change="yearChange" :allowClear="false">
                        <template #suffixIcon>
                            <DownOutlined style="color: #000;" />
                        </template>
                    </a-date-picker>
                </div>
                <BasicCard title="计划概况">
                    <a-descriptions class="descriptions">
                        <a-descriptions-item label="当年计划">{{ yearPlan }} kwh</a-descriptions-item>
                        <a-descriptions-item label="上年计划">{{ lastYearPlan }} kwh</a-descriptions-item>
                        <a-descriptions-item label="上年实际">{{ lastYear }} kwh</a-descriptions-item>
                        <a-descriptions-item label="同比上年实际">{{ yoyReal }}</a-descriptions-item>
                        <a-descriptions-item label="同比上年计划">{{ yoyPlan }}</a-descriptions-item>
                        <a-descriptions-item>
                            <slot name="label">
                                正常用量范围&nbsp;
                                <a-tooltip>
                                    <template #title>每月实际用量，超过此范围，系统自动产生告警！</template>
                                    <question-circle-outlined />：
                                </a-tooltip>
                            </slot>
                            <span>{{ setInfo.lowLimit || '~' }}% - {{ setInfo.highLimit || '~' }}%&nbsp;&nbsp;</span>
                            <form-outlined style="cursor: pointer;" @click="handleEditConfig"/>
                        </a-descriptions-item>
                    </a-descriptions>
                </BasicCard>
                <BasicCard title="计划详情">
                    <template #right>
                        <a-button @click="showModal">可视化查看</a-button>
                    </template>
                    <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
                        <template #slotPlanElectricityConsumption="{ row }">
                            <a-input v-if="editableData[row.id]" size="small"
                                v-model:value="editableData[row.id].planElectricityConsumption" />
                            <span v-else>{{ row.planElectricityConsumption }}</span>
                        </template>
                        <template #slotLastPlan="{ row }">
                            <template v-if="row.lastPlanElectricityConsumption">
                                <span v-if="row.planElectricityConsumption > row.lastPlanElectricityConsumption">+</span>
                                <span>{{ row.planElectricityConsumption - row.lastPlanElectricityConsumption }}</span>
                                <span> / </span>
                                <span v-if="row.planElectricityConsumption > row.lastPlanElectricityConsumption">+</span>
                                <span>{{ (((row.planElectricityConsumption - row.lastPlanElectricityConsumption) /
                                    row.lastPlanElectricityConsumption) * 100).toFixed(1) }}%</span>
                            </template>
                            <span v-else>- / -</span>
                        </template>
                        <template #slotLastReal="{ row }">
                            <template v-if="row.lastElectricityConsumption">
                                <span v-if="row.planElectricityConsumption > row.lastElectricityConsumption">+</span>
                                <span>{{ row.planElectricityConsumption - row.lastElectricityConsumption }}</span>
                                <span> / </span>
                                <span v-if="row.planElectricityConsumption > row.lastElectricityConsumption">+</span>
                                <span>{{ (((row.planElectricityConsumption - row.lastElectricityConsumption) /
                                    row.lastElectricityConsumption) * 100).toFixed(1) }}%</span>
                            </template>
                            <span v-else>- / -</span>
                        </template>
                    </basic-table>
                </BasicCard>
            </div>
        </template>
    </TwoColumnsLayout>
    <a-modal v-model:visible="open" :title="title" :footer="null" width="80%">
        <BaseChart v-if="chartOptions" width="100%" height="350px" :options="chartOptions"></BaseChart>
    </a-modal>
    <a-modal width="400px" title="设置正常使用范围" v-model:visible="configVisible" @ok="handleNext">
        <BasicForm
            ref="formRef"
            :labelCol="{ span: 6 }"
            :formItemsMap="formItemsMap"
            v-model:formData="formData"
        ></BasicForm>
    </a-modal>
</template>

<!-- eslint-disable no-undef -->
<script setup>
import { apiConfiguration } from "@/api/configuration";
import { ref } from 'vue';
import dayjs from 'dayjs';
import { useTreeState } from "@/components/business/LeftSelectTree/hooks/useTreeState";
import { apiElectricityPlan } from '@/api/energyAnalysis/electricityPlan';
import _ from 'lodash';
import { reactive, watch, computed } from 'vue';
import { BasicTable } from '@/components/basic/table'
import { Modal } from "ant-design-vue";
import { message } from "ant-design-vue";

const {
    treeData,
    treeLoading,
    selected,
    selectTree,
    firstLeafItem,
    selectedItem,
} = useTreeState({
    apiFunc: apiConfiguration.projectTree,
    initSelected: () => {
        if (!selected.value) {
            selectTree(firstLeafItem.value?.selectId ?? "");
        }
    },
    queryKey: "selectedProject",
    filterArrayDeepValueKey: "selectId",
});
watch(selectedItem, () => {
    tableRef.value.dataSourceRef = []
    yearChange(dayjs())
});

const nowYear = ref();
const title = ref('');

const tableRef = ref();
const tableConfig = reactive({
    showIndex: false,
    showSelect: false,
    pagination: false,
    isPageBottom: true,
    scroll: { y: 'calc(100vh - 400px)' },
    columns: [
        {
            title: "月份",
            type: "text",
            key: "month",
        },
        {
            title: "当年计划(kwh)",
            type: "customize",
            slotName: "slotPlanElectricityConsumption",
        },
        {
            title: "上年计划(kwh)",
            type: "text",
            key: "lastPlanElectricityConsumption",
        },
        {
            title: "上年实际(kwh)",
            type: "text",
            key: "lastElectricityConsumption",
        },
        {
            title: "同比上年计划",
            type: "customize",
            slotName: "slotLastPlan",
        },
        {
            title: "同比上年实际",
            type: "customize",
            slotName: "slotLastReal",
        },
    ],
    action: {
        title: "操作",
        width: 100,
        fixed: "right",
        items: [
            {
                icon: "FormOutlined",
                iconTip: '编辑',
                type: "icon",
                visibleFn: (row) => !editableData[row.id],
                onClick: (row) => {
                    handleEdit(row);
                },
            },
            {
                icon: "SaveOutlined",
                iconTip: '保存',
                type: "icon",
                visibleFn: (row) => editableData[row.id],
                onClick: (row) => {
                    handleSave(row);
                },
            },
            {
                icon: "ReloadOutlined",
                iconTip: '取消',
                type: "icon",
                visibleFn: (row) => editableData[row.id],
                onClick: (row) => {
                    handleCancel(row);
                },
            },
        ],
    },
})


const configVisible = ref(false);
const formRef = ref(null);
const handleNext = async()=>{
    try {
        await formRef.value.formRef.validateFields();
        if(isNaN(formData.lowLimit*1) || isNaN(formData.highLimit*1)){
            message.warning("请输入数字！");
            return;
        }
        configVisible.value = false;
        apiElectricityPlan.save_info({
            bizProjectId:selectedItem.value.projectBizId,
            year:currentYear.value,
            lowLimit:formData.lowLimit,
            highLimit:formData.highLimit,
        }).then(()=>{
            message.warning("保存成功！");
            initSetInfo()
        })

    } catch (error) {
        console.log("Failed:", error);
    }
   
}
const handleEditConfig = ()=>{
    configVisible.value = true;
    formData.highLimit = setInfo.value.highLimit
    formData.lowLimit = setInfo.value.lowLimit
}
const formData = reactive({
    lowLimit:'',
    highLimit:'',
})
const formItemsMap = reactive({
  lowLimit: {
    label: "预警下限",
    prop: "lowLimit",
    type: "input",
    required: true,
    suffix:'%',
    requiredMessage: "请输入",
  },
  highLimit: {
    label: "预警上限",
    prop: "highLimit",
    type: "input",
    required: true,
    suffix:'%',
    requiredMessage: "请输入",
  },
});


const yearPlan = computed(() => {
    return tableRef.value?.dataSourceRef.reduce((accumulator, current) => {
        return current.planElectricityConsumption ? accumulator + Number(current.planElectricityConsumption) : accumulator
    }, 0)
})
const lastYearPlan = computed(() => {
    return tableRef.value?.dataSourceRef.reduce((accumulator, current) => {
        return current.lastPlanElectricityConsumption ? accumulator + Number(current.lastPlanElectricityConsumption) : accumulator
    }, 0)
})
const lastYear = computed(() => {
    return tableRef.value?.dataSourceRef.reduce((accumulator, current) => accumulator + Number(current.lastElectricityConsumption), 0)
})
const yoyReal = computed(() => {
    console.log(yearPlan.value, lastYear.value);
    return (yearPlan.value - lastYear.value) + 'kwh / ' + (lastYear.value ? (((yearPlan.value - lastYear.value) / lastYear.value) * 100).toFixed(2) + '%' : '-')
})
const yoyPlan = computed(() => {
    return (yearPlan.value - lastYearPlan.value) + 'kwh / ' + (lastYearPlan.value ? (((yearPlan.value - lastYearPlan.value) / lastYearPlan.value) * 100).toFixed(2) + '%' : '-')
})

const setInfo = ref({});
const initSetInfo = ()=>{
    apiElectricityPlan.info({bizProjectId:selectedItem.value.projectBizId,year:currentYear.value}).then(res=>{
        setInfo.value = res.result || {};
    })
} 


const open = ref(false);
const chartOptions = ref(null);

const disabledDate = (current) => {
    return current && (current < dayjs().subtract(3, 'year') || current > dayjs().add(1, 'year'));
};

const currentYear = ref('');
const yearChange = (params) => {
    let year = dayjs(params).format('YYYY')
    currentYear.value = year;
    initSetInfo()
    let m = {
        projectBizId: selectedItem.value.projectBizId,
        year
    }

    title.value = `${selectedItem.value.name}${year}年月计划电量`

    apiElectricityPlan.list(m).then(({ result }) => {
        if (result.length === 0) {
            Modal.confirm({
                title: '消息提醒',
                content: `您选择的${selectedItem.value.name}项目，在${year}年没有计划电量，是否现在创建？`,
                onOk() {
                    apiElectricityPlan.init(m).then(() => {
                        apiElectricityPlan.list(m).then(({ result }) => {
                            tableRef.value.dataSourceRef = result
                            nowYear.value = params
                        })
                    })
                },
                okText: '是',
                cancelText: '取消'
            });
        } else {
            tableRef.value.dataSourceRef = result
            nowYear.value = params
        }
    })
}

const pageChange = (pageIndex) => {
    tableRef.value.paginationProps.current = pageIndex
    initTableData()
}

const editableData = reactive({});

const handleEdit = (row) => {
    let item = tableRef.value.dataSourceRef.find(item => row.id === item.id)
    editableData[row.id] = _.cloneDeep(item) || {}
};
const handleSave = (row) => {
    console.log(editableData[row.id].planElectricityConsumption);
    apiElectricityPlan.edit({
        id: row.id,
        planElectricityConsumption: editableData[row.id].planElectricityConsumption
    }).then(() => {
        let item = tableRef.value.dataSourceRef.find(item => row.id === item.id)
        Object.assign(item, editableData[row.id]);
        delete editableData[row.id];
    })

};
const handleCancel = (row) => {
    delete editableData[row.id];
};

const showModal = () => {
    open.value = true;
    initChart()
};

const initChart = () => {
    let option = {
        color: ['#85A5FF', '#597EF7', '#30BF78'],
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: 10,
            right: 10,
            bottom: 60,
            top: 30,
            containLabel: true
        },
        legend: {
            data: ['上年实际', '上年计划', '当年计划'],
            bottom: '3%',
        },
        xAxis: [
            {
                type: 'category',
                data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                axisPointer: {
                    type: 'shadow'
                }
            }
        ],
        yAxis: {
            type: 'value',
            name: 'kwh'
        },
        series: [
            {
                name: '上年实际',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' kwh';
                    }
                },
                data: tableRef.value.dataSourceRef.map(e => e.lastElectricityConsumption || 0)
            },
            {
                name: '上年计划',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' kwh';
                    }
                },
                data: tableRef.value.dataSourceRef.map(e => e.lastPlanElectricityConsumption || 0)
            },
            {
                name: '当年计划',
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' kwh';
                    }
                },
                data: tableRef.value.dataSourceRef.map(e => e.planElectricityConsumption || 0)
            }
        ]
    };
    chartOptions.value = option

}

</script>
<style lang='less' scoped>
.aside {
    position: relative;
    margin-right: 8px;
    width: 190px;
    min-width: 190px;
    background-color: #fff;

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        height: 56px;
        padding: 0px 16px;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.05);
    }

    &-content {
        padding: 11px 8px;

        &-all {
            padding: 0 4px;
            height: 24px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.content {
    padding: 0 20px;

    .header {
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }

    .title {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #D8D8D8;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);

    }
}
::v-deep .ant-descriptions-item-content{
    display: flex !important;
    align-items: center !important;
}

.descriptions {
    padding: 17px 20px 0 20px;
    border: 1px solid #E6E8EB;
    border-radius: 4px;
}
</style>